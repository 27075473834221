import React from "react"
import IntegrationTemplate from "../../templates/integration"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="google-drive"
    name="Google Drive"
    shortDescription="Store website files and database backups on Google Drive."
    website={"https://www.google.be/drive/"}
    links={[{text: 'Documentation', url: '/docs'}]}
    category="Storage"
    logo="googledrive-ico.png"
    metaTitle={meta.title.replace('##provider##', 'Google Drive')}
    metaDescription={meta.description.replace('##provider##', 'Google Drive')}
  >
    <IntegrationStorageContent
      keyRef="googledrive"
      service="Google Drive"
      provider="Google Drive"
      learnTags={['googledrive']}
    />
  </IntegrationTemplate>
)

export default ContentPage
